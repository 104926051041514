<!-- index -->
<template>
  <div class="report">
    <div
      class="download-btn"
      @click="printOut(WXBasicInfo.sysTopic)"
      :class="isDisabled ? 'download-btn-disabled' : ''"
    >
      <img v-if="isDisabled" :src="downloadReportDisabledImg" alt="" />
      <img v-else :src="downloadReportImg" alt="" />
      下载报告
    </div>
    <div class="report-content" id="reportContent">
      <div class="page1 page-part flex-content">
        <div class="left">
          <img
            class="mag-cover"
            :src="WXBasicInfo.magCoverId ? magCover : noImg"
            alt=""
          />
        </div>
        <div class="right">
          <div class="title">{{ WXBasicInfo.sysTopic }}</div>
          <div class="title">学术文献传播分析报告</div>
          <div class="line"></div>
          <ul>
            <li>发文分析</li>
            <li>邮件传播</li>
            <li>微信传播</li>
            <li>传播效果</li>
          </ul>
        </div>
      </div>
      <div class="page2 page-part">
        <ul class="tab-ul">
          <li class="active">
            <span class="yq">01</span>
            <span></span>
            发文分析
          </li>
          <li>
            <span class="yq">02</span>
            邮件传播
          </li>
          <li>
            <span class="yq">03</span>
            微信传播
          </li>
          <li>
            <span class="yq">04</span>
            传播效果
          </li>
        </ul>
      </div>
      <div class="page3 page-part">
        <div class="echarts-first-title">发文分析</div>
        <div class="echarts-second-title">引用期刊分布（近五年，Top15）</div>
        <div
          id="chartYYQK"
          :style="{
            width: '100%',
            height: getTableContentHeight('page3', false, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：2016年至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计《{{
                basicInfo.magName
              }}》近五年来所引用文章的期刊来源，图中从左向右展示引用次数由高到低的前15个引用来源期刊，虚线连接的数据点为这些期刊当前的影响因子（知网复合影响因子）。
            </div>
          </div>
        </div>
      </div>
      <div class="page4 page-part">
        <div class="echarts-first-title">发文分析</div>
        <div class="echarts-second-title">引证期刊分布（近五年，Top15）</div>
        <div
          id="chartYZQK"
          :style="{
            width: '100%',
            height: getTableContentHeight('page4', false, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：2016年至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计《{{
                basicInfo.magName
              }}》近五年来被引用文章的发文期刊分布，图中从左向右展示被引用次数由高到低的前15个被引用期刊，虚线连接的数据点为这些期刊当前的影响因子（知网复合影响因子）。
            </div>
          </div>
        </div>
      </div>
      <div class="page5 page-part">
        <div class="echarts-first-title">发文分析</div>
        <div
          class="table-content"
          :style="getTableContentHeight('page5', false)"
        >
          <div class="table-content-box">
            <div class="echarts-second-title">
              本刊机构发文统计（近五年，Top10）
            </div>
            <commonTable
              ref="JGFWTJTable"
              no-pagination
              :table-data="JGFWTJTableData"
              :column-data="JGFWTJColumnFive"
            />
          </div>
        </div>
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：2016年至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计《{{
                basicInfo.magName
              }}》近五年来发表文章作者的所在机构，图中依次展示发文数量由高到低的前10个机构，该机构为细化到学院或实验室等具有一定学科方向的机构。
            </div>
          </div>
        </div>
      </div>
      <div class="page6 page-part">
        <div class="echarts-first-title">发文分析</div>
        <div
          class="table-content"
          :style="getTableContentHeight('page6', false)"
        >
          <div class="table-content-box">
            <div class="echarts-second-title">
              引用机构分布（近五年，Top10）
            </div>
            <commonTable
              ref="YYJGTable"
              no-pagination
              :table-data="YYJGTableData"
              :column-data="YYJGColumnFive"
            />
          </div>
        </div>
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：2016年至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计《{{
                basicInfo.magName
              }}》近五年来引用文章作者的所在机构，图中依次展示发文数量由高到低的前10个机构，该机构为细化到学院或实验室等具有一定学科方向的机构。
            </div>
          </div>
        </div>
      </div>
      <div class="page7 page-part">
        <div class="echarts-first-title">发文分析</div>
        <div
          class="table-content"
          :style="getTableContentHeight('page7', false)"
        >
          <div class="table-content-box">
            <div class="echarts-second-title">
              引证机构分布（近五年，Top10）
            </div>
            <commonTable
              ref="YZJGTable"
              no-pagination
              :table-data="YZJGTableData"
              :column-data="YZJGColumnFive"
            />
          </div>
        </div>
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：2016年至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计《{{
                basicInfo.magName
              }}》近五年来被引用文章作者的所在机构，图中依次展示发文数量由高到低的前10个机构，该机构为细化到学院或实验室等具有一定学科方向的机构。
            </div>
          </div>
        </div>
      </div>
      <div class="page8 page-part">
        <ul class="tab-ul">
          <li>
            <span class="yq">01</span>
            发文分析
          </li>
          <li class="active">
            <span class="yq">02</span>
            邮件传播
          </li>
          <li>
            <span class="yq">03</span>
            微信传播
          </li>
          <li>
            <span class="yq">04</span>
            传播效果
          </li>
        </ul>
      </div>
      <div class="page9 page-part">
        <div class="echarts-first-title">邮件传播——概览</div>
        <div class="situation">
          <div class="situation-info">
            <div class="title">推送用户情况</div>
            <div class="info">
              <span class="text-box">
                用户送达人数
                <span>
                  {{ basicInfo.pushSuccessCount || 0 }}
                </span>
              </span>
              <span class="text-box ml-percent16">
                白名单用户数
                <span>
                  {{ userDataInfo.countWhiteListAuthor || 0 }}
                </span>
              </span>
              <span class="text-box mt-percent16">
                机构覆盖量
                <span>
                  {{ userDataInfo.countOrg || 0 }}
                </span>
              </span>
              <span class="text-box ml-percent16 mt-percent16">
                期刊覆盖量
                <span>
                  {{ userDataInfo.countMag || 0 }}
                </span>
              </span>
            </div>
          </div>
          <div class="situation-info">
            <div class="title">阅读邮件/文章情况</div>
            <div class="info">
              <span class="text-box">
                阅读邮件次数
                <span>
                  {{ basicInfo.emailOPenCount || 0 }}
                </span>
              </span>
              <span class="text-box ml-percent16">
                阅读邮件人数
                <span>
                  {{ basicInfo.onlyEmailOPenCount || 0 }}
                </span>
              </span>
              <span class="text-box mt-percent16">
                阅读文章次数
                <span>
                  {{ basicInfo.readCount || 0 }}
                </span>
              </span>
              <span class="text-box ml-percent16 mt-percent16">
                阅读文章人数
                <span>
                  {{ basicInfo.onlyReadCount || 0 }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="page10 page-part">
        <div class="echarts-first-title">邮件传播——用户来源</div>
        <div class="echarts-left-title">
          推送用户
          <span>{{ basicInfo.pushSuccessCount || 0 }}人</span>
        </div>
        <div
          class="table-content"
          :style="getTableContentHeight('page10', true)"
        >
          <div class="table-content-box">
            <div class="echarts-second-title">推送用户来源分布</div>
            <commonTable
              ref="TSYHTable"
              no-serial
              no-pagination
              :table-data="TSYHLYTableData"
              :column-data="TSYHLYColumnData"
            />
          </div>
        </div>
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            制图说明：通过对《{{
              basicInfo.magName
            }}》的发文情况进行分析，从期刊和发文机构两个维度构建推送用户群体，<br />
            （1）本刊白名单为刊社提供的用户信息<br />
            （2）本刊作者、引用刊作者和引证刊作者是从期刊维度构建的推送用户群体，分别表示本刊中的文章作者、引用期刊中的文章作者、引证期刊（本刊文章被引期刊）中的文章作者<br />
            （3）本刊机构学者、引用刊机构学者和引证刊机构学者是从发文机构的维度构建的推送用户群体，分别表示本刊发文作者所在机构的其他学者用户、引用刊作者所在机构的其他学者用户、引证刊作者所在机构的其他学者用户（按照高发文、高引证、高引用获取学者信息）<br />
            （4）本领域学者表示，通过当期发文的研究领域、关键词，在WOS等专业网站和数据库中获得的推送用户数据
          </div>
        </div>
      </div>
      <div class="page11 page-part">
        <div class="echarts-first-title">邮件传播——推送用户机构分析</div>
        <div class="echarts-left-title">
          推送用户覆盖机构
          <span>{{ userDataInfo.countOrg || 0 }}家</span>
        </div>
        <div class="echarts-second-title">推送用户机构分布（Top20）</div>
        <div
          id="chartTSYHJG"
          :style="{
            width: '100%',
            height: getTableContentHeight('page11', true, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            制图说明：统计{{
              WXBasicInfo.sysTopic
            }}邮件推送用户的所在机构，图中依次展示人数由高到低的前20个机构。本次推送用户覆盖机构{{
              userDataInfo.countOrg || 0
            }}家。
          </div>
        </div>
      </div>
      <div class="page12 page-part">
        <div class="echarts-first-title">邮件传播——推送用户期刊分析</div>
        <div class="echarts-left-title">
          推送用户期刊覆盖量
          <span>{{ userDataInfo.countMag || 0 }}刊</span>
        </div>
        <div class="echarts-second-title">推送用户期刊分布（Top20）</div>
        <div
          id="chartTSYHQK"
          :style="{
            width: '100%',
            height: getTableContentHeight('page12', true, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            制图说明：统计{{
              WXBasicInfo.sysTopic
            }}邮件推送用户的期刊分布，体现了本次推送用户发表文章的期刊分布情况，图中依次展示人数由高到低的前20个期刊。本次推送用户期刊覆盖量{{
              userDataInfo.countMag || 0
            }}本刊。
          </div>
        </div>
      </div>
      <div class="page13 page-part">
        <div class="echarts-first-title">邮件传播——邮件阅读情况</div>
        <div class="echarts-left-title">
          邮件阅读次数
          <span>{{ basicInfo.emailOPenCount || 0 }}次</span>， 阅读人数
          <span>{{ basicInfo.onlyEmailOPenCount || 0 }}人</span>
        </div>
        <div class="echarts-second-title">阅读邮件用户的机构分布（Top20）</div>
        <div
          id="chartYJYHJG"
          :style="{
            width: '100%',
            height: getTableContentHeight('page13', true, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：推送日至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}邮件推送后，阅读邮件用户的所在机构，图中依次展示机构人数由高到低的前20个机构。本次推送后
              {{ basicInfo.onlyEmailOPenCount || 0 }}人阅读了
              {{ basicInfo.emailOPenCount || 0 }}次邮件。
            </div>
          </div>
        </div>
      </div>
      <div class="page14 page-part">
        <div class="echarts-first-title">邮件传播——邮件阅读情况</div>
        <div class="echarts-second-title">阅读邮件用户的期刊分布（top 20）</div>
        <div
          id="chartYJYHQK"
          :style="{
            width: '100%',
            height: getTableContentHeight('page14', false, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：推送日至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}邮件推送后，阅读邮件用户的发文期刊，图中依次展示期刊人数由高到低的前20个期刊。
            </div>
          </div>
        </div>
      </div>
      <div class="page15 page-part">
        <div class="echarts-first-title">邮件传播——文章阅读情况</div>
        <div
          class="table-content"
          :style="getTableContentHeight('page15', false)"
        >
          <div class="table-content-box">
            <div class="echarts-second-title">文章阅读量排行</div>
            <commonTable
              no-pagination
              :table-data="WZYDLTableData"
              :column-data="WZYDLColumnData"
            />
          </div>
        </div>
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：推送日起至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}邮件推送后的文章阅读量排行，阅读量是指通过点击邮件中的链接来阅读文章的次数。
            </div>
          </div>
        </div>
      </div>
      <div class="page16 page-part">
        <div class="echarts-first-title">邮件传播——文章阅读情况</div>
        <div class="echarts-second-title">阅读文章用户的机构分布（Top15）</div>
        <div
          id="chartWZYHJG"
          :style="{
            width: '100%',
            height: getTableContentHeight('page16', false, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：推送日至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}邮件推送后，通过点击邮件中的链接来阅读文章的用户的所在机构，图中依次展示机构人数由高到低的前15个机构。
            </div>
          </div>
        </div>
      </div>
      <div class="page17 page-part">
        <div class="echarts-first-title">邮件传播——文章阅读情况</div>
        <div class="echarts-second-title">阅读文章用户的期刊分布（Top20）</div>
        <div
          id="chartWZYHQK"
          :style="{
            width: '100%',
            height: getTableContentHeight('page17', false, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：推送日至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}邮件推送后，通过点击邮件中的链接来阅读文章的用户的发文期刊，图中依次展示期刊人数由高到低的前20个期刊。
            </div>
          </div>
        </div>
      </div>
      <div class="page18 page-part">
        <ul class="tab-ul">
          <li>
            <span class="yq">01</span>
            发文分析
          </li>
          <li>
            <span class="yq">02</span>
            邮件传播
          </li>
          <li class="active">
            <span class="yq">03</span>
            微信传播
          </li>
          <li>
            <span class="yq">04</span>
            传播效果
          </li>
        </ul>
      </div>
      <div class="page19 page-part">
        <div class="echarts-first-title">微信传播</div>
        <div class="echarts-left-title">
          微网刊访问
          <span>{{ netWorkCount || 0 }}次</span>， 文章阅读
          <span>{{ articleCount || 0 }}次</span>
        </div>
        <div
          class="table-content"
          :style="getTableContentHeight('page19', true)"
        >
          <div class="table-content-box">
            <div class="echarts-second-title">微网刊文章阅读排行</div>
            <commonTable
              no-pagination
              :table-data="WXWZYDLTableData"
              :column-data="WXWZYDLColumnData"
            />
          </div>
        </div>
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">数据统计时间：推送日起至统计日</div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}微网刊发布后，通过微网刊阅读文章的阅读量排行，微网刊访问{{
                netWorkCount || 0
              }}次，文章阅读{{
                articleCount || 0
              }}次，微网刊访问次数是指访问各期文章目录页的次数。
            </div>
          </div>
        </div>
      </div>
      <div class="page20 page-part">
        <ul class="tab-ul">
          <li>
            <!-- <img src="@assets/img/01.png" alt="" /> -->
            <span class="yq">01</span>
            发文分析
          </li>
          <li>
            <span class="yq">02</span>
            邮件传播
          </li>
          <li>
            <span class="yq">03</span>
            微信传播
          </li>
          <li class="active">
            <span class="yq">04</span>
            传播效果
          </li>
        </ul>
      </div>
      <div class="page21 page-part">
        <div class="echarts-first-title">传播效果</div>
        <div
          class="table-content"
          :style="getTableContentHeight('page21', false)"
        >
          <div class="table-content-box">
            <div class="echarts-second-title">文章阅读排行</div>
            <commonTable
              no-pagination
              :table-data="CBXGWZYDLTableData"
              :column-data="CBXGWZYDLColumnData"
            />
          </div>
        </div>
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">
              数据统计时间：邮件推送日/微网刊发布日至统计日
            </div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}主动传播后的文章阅读量排行。其中阅读量（邮件）表示通过点击邮件中链接来阅读文章的次数，阅读量（微信）表示通过微网刊来阅读文章的次数，阅读量（官网）表示官网中显示的浏览量，为html、pdf、摘要等的总和。需要注意的是，阅读量（邮件）和阅读量（微信）都将浏览数据导流到了官网，也就是官网的浏览数据包含了邮件和微信的浏览数据。
            </div>
          </div>
        </div>
      </div>
      <div class="page22 page-part">
        <div class="echarts-first-title">传播效果</div>
        <div class="echarts-second-title">传播前后各篇文章阅读情况</div>
        <div
          id="chartWZYDQK"
          :style="{
            width: '100%',
            height: getTableContentHeight('page22', false, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">
              数据统计时间：邮件推送日/微网刊发布日至统计日/新刊期出刊日
            </div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}主动传播前后各篇文章的阅读次数。微网刊发布前/邮件推送前的阅读量是指微网刊发布前/邮件推送前一天官网显示的浏览量，若未采集到前一天的数据，则依次往前采集。当前阅读量是指该篇文章当前在官网显示的浏览量。
            </div>
          </div>
        </div>
      </div>
      <div class="page23 page-part">
        <div class="echarts-first-title">传播效果</div>
        <div class="echarts-second-title">传播前后官网访问情况</div>
        <div
          id="chartGWFW"
          :style="{
            width: '100%',
            height: getTableContentHeight('page23', false, true)
          }"
        />
        <div class="position-bottom">
          <div class="echarts-line">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line1"></div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-left">
              数据统计时间：邮件推送日/微网刊发布日至统计日/新刊期出刊日
            </div>
            <div class="bottom-info-right">
              制图说明：统计{{
                WXBasicInfo.sysTopic
              }}主动传播前后官网访问量情况。官网访问量特指通过阅读本期文章带来的访问量。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import commonTable from '@comp/commonTable'
import { getRecentYears } from '@assets/js/utils'
import { getPdf } from '@assets/js/htmlToPdf.js'
const api = ProjectConfig.emailPush
const CBXGApi = ProjectConfig.communicationEffectStatistics
const WXApi = ProjectConfig.weChatPush
const contentAggregationApi = ProjectConfig.contentAggregation
export default {
  name: 'Report',
  data() {
    return {
      noImg: require('@assets/img/noImg.jpg'),
      downloadReportImg: require('@assets/img/download-report.png'),
      downloadReportDisabledImg: require('@assets/img/download-report-disabled.png'),
      coverUrl: contentAggregationApi.GetPictureStream.url,
      isDisabled: true,
      basicInfo: {},
      WXBasicInfo: {},
      magCover: '',
      JGFWTJTableData: [],
      JGFWTJColumnFive: [
        {
          title: '发文机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        },
        {
          title: '平均被引',
          prop: 'referedCount5',
          notSortable: true
        },
        {
          title: '单篇最高被引',
          width: '130px',
          prop: 'singleReferedCount5',
          notSortable: true
        }
      ],
      YYJGTableData: [],
      YYJGColumnFive: [
        {
          title: '引用机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        }
      ],
      YZJGTableData: [],
      YZJGColumnFive: [
        {
          title: '引证机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        }
      ],
      userDataInfo: {},
      TSYHLYTableData: [],
      TSYHLYColumnData: [
        {
          title: '来源',
          notSortable: true,
          prop: 'source'
        }
      ],
      WZYDLTableData: [],
      WZYDLColumnData: [
        {
          title: '标题',
          prop: 'topic',
          special: true,
          width: '320px',
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '年/卷/期',
          prop: 'pubYearStage',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '阅读量（邮件）',
          prop: 'clickCount',
          notSortable: true
        }
      ],
      WXWZYDLTableData: [],
      WXWZYDLColumnData: [
        {
          title: '标题',
          prop: 'title',
          special: true,
          width: '300px',
          notSortable: true,
          clickParam: 'htmlUrl'
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keyWords',
          notSortable: true
        },
        {
          title: '阅读量',
          prop: 'networkCount',
          notSortable: true
        }
      ],
      CBXGWZYDLTableData: [],
      CBXGWZYDLColumnData: [
        {
          title: '标题',
          prop: 'topic',
          special: true,
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '年/卷/期',
          prop: 'pubYearStage',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '阅读量（邮件）',
          prop: 'emailClickCount',
          width: '120px',
          notSortable: true
        },
        {
          title: '阅读量（微信）',
          prop: 'wechatClickCount',
          width: '120px',
          notSortable: true
        },
        {
          title: '阅读量（官网）',
          prop: 'clickCount',
          width: '120px',
          notSortable: true
        }
      ],
      netWorkCount: '',
      articleCount: ''
    }
  },
  props: {},
  components: { commonTable },
  computed: {
    ...mapState('incrementService', ['magId']),
    taskId() {
      return this.$route.query.taskId
    }
  },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.getData()
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  mounted() {
    window.addEventListener('resize', this.adjustTable)
    this.$nextTick(() => {
      if (this.magId) {
        this.getData()
      }
    })
  },
  methods: {
    getMagCover() {
      let url = '/das-api/vas/push/configManage/getPictureBase'
      let params = {
        pictureId: this.WXBasicInfo.magCoverId
      }
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.magCover = 'data:image/jpg;base64,' + data.data
        }
      })
    },
    printOut(name) {
      if (this.isDisabled) {
        return
      }
      this.$nextTick(() => {
        let pdfWidth = document.getElementsByClassName('page1')[0].clientWidth
        let pdfHeight = document.getElementsByClassName('page1')[0].clientHeight
        getPdf('reportContent', name, pdfWidth, pdfHeight)
      })
    },
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('myChart')) {
        echarts.init(document.getElementById('myChart')).resize()
      }
      if (document.getElementById('chartYYQK')) {
        echarts.init(document.getElementById('chartYYQK')).resize()
      }
      if (document.getElementById('chartYZQK')) {
        echarts.init(document.getElementById('chartYZQK')).resize()
      }
      if (document.getElementById('chartTSYHJG')) {
        echarts.init(document.getElementById('chartTSYHJG')).resize()
      }
      if (document.getElementById('chartTSYHQK')) {
        echarts.init(document.getElementById('chartTSYHQK')).resize()
      }
      if (document.getElementById('chartYJYHJG')) {
        echarts.init(document.getElementById('chartYJYHJG')).resize()
      }
      if (document.getElementById('chartYJYHQK')) {
        echarts.init(document.getElementById('chartYJYHQK')).resize()
      }
      if (document.getElementById('chartWZYHJG')) {
        echarts.init(document.getElementById('chartWZYHJG')).resize()
      }
      if (document.getElementById('chartWZYHQK')) {
        echarts.init(document.getElementById('chartWZYHQK')).resize()
      }
      if (document.getElementById('chartWZYDQK')) {
        echarts.init(document.getElementById('chartWZYDQK')).resize()
      }
      if (document.getElementById('chartGWFW')) {
        echarts.init(document.getElementById('chartGWFW')).resize()
      }
    },
    getData() {
      Promise.all([
        // 任务基本信息
        this.getTaskInfo(),
        // 微网刊基本信息
        this.getNetworkJournalInfo(),
        // 推送用户概况
        this.getUserData(),
        // 引用期刊分布
        this.getChartData(
          'YYQK',
          {
            magId: this.magId,
            page: 0,
            size: 15,
            sort: 'totalCount5,desc'
          },
          api.ReferenceMag.url
        ),
        // 引证期刊分布
        this.getChartData(
          'YZQK',
          {
            magId: this.magId,
            page: 0,
            size: 15,
            sort: 'totalCount5,desc'
          },
          api.CitationMag.url
        ),
        // 机构发文统计
        this.getJGFWTJTableData(),
        // 引用机构分布
        this.getYYJGTableData(),
        // 引证机构分布
        this.getYZJGTableData(),
        // 推送用户的来源分布
        this.getChartData(
          'TSYHLY',
          { taskId: this.taskId },
          api.GetSourceType.url
        ),
        // 推送用户的机构分布
        this.getChartData(
          'TSYHJG',
          { taskId: this.taskId, page: 0, size: 20 },
          api.GetPushMagAuthorOrg.url
        ),
        // 推送用户的期刊分布
        this.getChartData(
          'TSYHQK',
          { taskId: this.taskId, page: 0, size: 20 },
          api.GetMag.url
        ),
        // 阅读邮件用户的机构分布
        this.getChartData(
          'YJYHJG',
          { taskId: this.taskId, page: 0, size: 20 },
          api.GetOpenEmailOrg.url
        ),
        // 阅读邮件用户的期刊分布
        this.getChartData(
          'YJYHQK',
          { taskId: this.taskId, page: 0, size: 20 },
          api.GetOpenEmailMag.url
        ),
        // 邮件文章阅读量排行
        this.getWZYDLTableData(),
        // 阅读文章用户的机构分布
        this.getChartData(
          'WZYHJG',
          { taskId: this.taskId, page: 0, size: 15 },
          api.GetReadArticleOrg.url
        ),
        // 阅读文章用户的期刊分布
        this.getChartData(
          'WZYHQK',
          { taskId: this.taskId, page: 0, size: 20 },
          api.GetReadArticleMag.url
        ),
        // 微信文章阅读量排行
        this.getWXWZYDLTableData()
      ]).then(() => {
        this.isDisabled = false
      })
    },
    // 推送用户情况
    getTaskInfo() {
      const params = {
        taskId: this.taskId
      }
      let url = api.GetTaskInfo.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.basicInfo = data
            //传播效果文章阅读量排行
            this.getCBXGWZYDLTableData()
            let params = {
              magId: this.magId,
              type: this.basicInfo.type,
              year: this.basicInfo.year,
              issue: this.basicInfo.issue || ''
            }
            //推送前后单篇文章阅读情况
            this.getChartData('WZYDQK', params, CBXGApi.GetVisitCount.url)
            // 获取官网访问echarts数据
            this.getChartData('GWFW', params, CBXGApi.GetSumVisitCount.url)
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    getNetworkJournalInfo() {
      const params = {
        id: this.$route.query.networkId
      }
      let url = WXApi.GetNetworkJournalInfo.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.WXBasicInfo = data
            // 微网刊列表
            this.getNetWorkArticles()
            // 封面
            this.getMagCover()
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 获取历史所有微网刊
    getNetWorkArticles(pageParams = {}) {
      const { page = 1, size = 999, sort = 'year,issue,desc' } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort,
        isPublish: 1, // 1是已发布  0是未发布
        isShow: false
      }
      let url = WXApi.GetNetWorkArticles.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            this.loading = false
            const { data } = res.data
            data.content.map((item) => {
              if (item.id === this.WXBasicInfo.id) {
                this.netWorkCount = item.netWorkCount //微网刊访问次数
                this.articleCount = item.articleCount //文章阅读次数
              }
            })
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            this.loading = false
            const { data } = res.data
            let _this = this
            this.$nextTick(() => {
              if (type === 'YYQK') {
                _this.chartYYQK(data)
              } else if (type === 'YZQK') {
                _this.chartYZQK(data)
              } else if (type === 'TSYHLY') {
                _this.handleTSYHLYInfo(data)
              } else if (type === 'TSYHJG') {
                _this.chartTSYHJG(data)
              } else if (type === 'TSYHQK') {
                _this.chartTSYHQK(data)
              } else if (type === 'YJYHJG') {
                _this.chartYJYHJG(data)
              } else if (type === 'YJYHQK') {
                _this.chartYJYHQK(data)
              } else if (type === 'WZYHJG') {
                _this.chartWZYHJG(data)
              } else if (type === 'WZYHQK') {
                _this.chartWZYHQK(data)
              } else if (type === 'WZYDQK') {
                _this.chartWZYDQK(data)
              } else if (type === 'GWFW') {
                _this.chartGWFW(data)
              }
              resolve()
            })
          } else {
            reject()
          }
        })
      })
    },
    // 引用期刊分布
    chartYYQK(data) {
      if (!document.getElementById('chartYYQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYYQK'))
      const titleArr = []
      const threeYearsArr = []
      const fiveYearsArr = []
      const impactFactorArr = []
      if (data && data.content.length > 0) {
        data.content.map((item) => {
          titleArr.push(item.refMagName)
          threeYearsArr.push(item.totalCount3)
          fiveYearsArr.push(item.totalCount5)
          impactFactorArr.push(item.influence1)
        })
      }
      myChart.setOption(
        {
          color: ['#BBE0E3', '#0070C0', '#F7B8A9'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['近3年合计', '近5年合计', '影响因子']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '25%',
            top: '10%'
          },
          yAxis: [
            {
              type: 'value',
              name: '引用次数'
            },
            {
              type: 'value',
              name: '影响因子'
            }
          ],
          series: [
            {
              name: '近3年合计',
              type: 'bar',
              data: threeYearsArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '近5年合计',
              type: 'bar',
              data: fiveYearsArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '影响因子',
              type: 'line',
              yAxisIndex: 1,
              lineStyle: {
                type: 'dashed'
              },
              symbol: 'star',
              symbolSize: 8,
              data: impactFactorArr,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 引证期刊分布
    chartYZQK(data) {
      if (!document.getElementById('chartYZQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYZQK'))
      const titleArr = []
      const threeYearsArr = []
      const fiveYearsArr = []
      const impactFactorArr = []
      if (data && data.content.length > 0) {
        data.content.map((item) => {
          titleArr.push(item.ctiMagName)
          threeYearsArr.push(item.totalCount3)
          fiveYearsArr.push(item.totalCount5)
          impactFactorArr.push(item.influence1)
        })
      }
      myChart.setOption(
        {
          color: ['#BBE0E3', '#0070C0', '#F7B8A9'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['近3年合计', '近5年合计', '影响因子']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '25%',
            top: '10%'
          },
          yAxis: [
            {
              type: 'value',
              name: '引证次数'
            },
            {
              type: 'value',
              name: '影响因子'
            }
          ],
          series: [
            {
              name: '近3年合计',
              type: 'bar',
              data: threeYearsArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '近5年合计',
              type: 'bar',
              data: fiveYearsArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '影响因子',
              type: 'line',
              yAxisIndex: 1,
              lineStyle: {
                type: 'dashed'
              },
              symbol: 'star',
              symbolSize: 8,
              data: impactFactorArr,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 机构发文统计
    getJGFWTJTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        type: 'mag',
        sort: 'totalCount5,desc',
        top: 100
      }
      let url = api.Institution.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.JGFWTJTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 引用机构分布
    getYYJGTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: 'totalCount5,desc',
        top: 100
      }
      let url = api.ReferenceInstitution.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.YYJGTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 引证机构分布
    getYZJGTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: 'totalCount5,desc',
        top: 100
      }
      let url = api.CitationInstitution.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.YZJGTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 推送用户概况
    getUserData() {
      const params = {
        taskId: this.taskId
      }
      let url = api.GetSum.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.userDataInfo = data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 推送用户来源分布
    handleTSYHLYInfo(data) {
      let columnObj, countObj
      countObj = {
        source: '人数'
      }
      data.sourceTypList.map((item, index) => {
        columnObj = {
          title: item.sourceType,
          notSortable: true,
          prop: 'count' + index,
          width: item.sourceType.length * 18.5 + 'px'
        }
        countObj['count' + index] = item.count
        this.TSYHLYColumnData.push(columnObj)
      })
      this.TSYHLYTableData.push(countObj)
    },
    // 推送用户的机构分布echarts图
    chartTSYHJG(data) {
      if (!document.getElementById('chartTSYHJG')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartTSYHJG'))
      const titleArr = []
      const countArr = []
      if (data.orgList.length === 0) {
        return false
      }
      data.orgList.map((item) => {
        titleArr.push(item.orgName)
        countArr.push(item.count)
      })
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '13%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 推送用户的期刊分布echarts图
    chartTSYHQK(data) {
      if (!document.getElementById('chartTSYHQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartTSYHQK'))
      const titleArr = []
      const countArr = []
      if (data.magList.length === 0) {
        return false
      }
      data.magList.map((item) => {
        titleArr.push(item.magName)
        countArr.push(item.count)
      })
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '13%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 阅读邮件用户的机构分布echarts图
    chartYJYHJG(data) {
      if (!document.getElementById('chartYJYHJG')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYJYHJG'))
      const titleArr = []
      const countArr = []
      if (data.openEmailOrgList.length > 0) {
        data.openEmailOrgList.map((item) => {
          titleArr.push(item.orgName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '14%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 阅读邮件用户的期刊分布echarts图
    chartYJYHQK(data) {
      if (!document.getElementById('chartYJYHQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYJYHQK'))
      const titleArr = []
      const countArr = []
      if (data.openEmailMagList.length > 0) {
        data.openEmailMagList.map((item) => {
          titleArr.push(item.magName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '13%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '期刊人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 文章阅读量排行
    getWZYDLTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        taskId: this.taskId
      }
      let url = api.GetArticleClickList.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.WZYDLTableData = content
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 阅读文章用户的机构分布echarts图
    chartWZYHJG(data) {
      if (!document.getElementById('chartWZYHJG')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartWZYHJG'))
      const titleArr = []
      const countArr = []
      if (data && data.readArticleOrgList.length > 0) {
        data.readArticleOrgList.map((item) => {
          titleArr.push(item.orgName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '13%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 阅读文章用户的期刊分布echarts图
    chartWZYHQK(data) {
      if (!document.getElementById('chartWZYHQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartWZYHQK'))
      const titleArr = []
      const countArr = []
      if (data && data.readArticleMag.length > 0) {
        data.readArticleMag.map((item) => {
          titleArr.push(item.magName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '13%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 微信文章阅读量排行
    getWXWZYDLTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        networkId: this.$route.query.networkId || ''
      }
      let url = WXApi.GetArticleAccess.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.WXWZYDLTableData = content
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 传播效果文章阅读量排行
    getCBXGWZYDLTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        magId: this.magId,
        type: this.basicInfo.type,
        year: this.basicInfo.year,
        issue: this.basicInfo.issue || ''
      }
      let url = CBXGApi.ArticleClickRankList.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data) {
            const { content = [] } = data
            this.CBXGWZYDLTableData = content
          }
        }
      })
    },
    // 推送前后单篇文章阅读情况echarts图
    chartWZYDQK(data) {
      if (!document.getElementById('chartWZYDQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartWZYDQK'))
      const titleArr = []
      const startVisitCountArr = []
      const endVisitCountArr = []
      const networkStartVisitCountArr = []
      let XbarWidth = null
      if (data && data.pushArticleVisitMapList.length > 0) {
        data.pushArticleVisitMapList.map((item) => {
          item.title = item.title.replace(/<[^>]+>/g, '')
          titleArr.push(item.title)
          startVisitCountArr.push(item.startVisitCount || 0)
          endVisitCountArr.push(item.endVisitCount || 0)
          networkStartVisitCountArr.push(item.networkStartVisitCount || 0)
        })
      }
      networkStartVisitCountArr.map((item) => {
        if (item !== 0) {
          this.networkStartVisitLegend = true
        }
      })
      startVisitCountArr.map((item) => {
        if (item !== 0) {
          this.startVisitLegend = true
        }
      })
      endVisitCountArr.map((item) => {
        if (item !== 0) {
          this.endVisitLegend = true
        }
      })
      XbarWidth = titleArr.length >= 15 ? '' : 20
      let _this = this
      myChart.setOption(
        {
          color: ['#65A085', '#BBE0E3', '#265cca'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['微网刊发布前', '邮件推送前', '当前阅读'],
            selected: {
              微网刊发布前: _this.networkStartVisitLegend,
              邮件推送前: _this.startVisitLegend,
              当前阅读: _this.endVisitLegend
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '20%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '阅读次数'
            }
          ],
          series: [
            {
              name: '微网刊发布前',
              type: 'bar',
              data: networkStartVisitCountArr,
              barWidth: XbarWidth,
              barMaxWidth: 30,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '邮件推送前',
              type: 'bar',
              data: startVisitCountArr,
              barWidth: XbarWidth,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '当前阅读',
              type: 'bar',
              data: endVisitCountArr,
              barWidth: XbarWidth,
              barMaxWidth: 30,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 官网访问
    chartGWFW(data) {
      if (!document.getElementById('chartGWFW')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartGWFW'))
      const timeArr = []
      const countArr = []
      let _this = this
      if (data && data.sumVisitCountList.length > 0) {
        let emailPublishTime = data.emailPublishTime
        let wechatPublishTime = data.wechatPublishTime
        this.leftPushlishTime =
          data.emailPublishTime > data.wechatPublishTime
            ? data.wechatPublishTime
            : data.emailPublishTime
        this.rightPushlishTime =
          data.wechatPublishTime > data.emailPublishTime
            ? data.wechatPublishTime
            : data.emailPublishTime
        data.sumVisitCountList.map((item) => {
          if (
            emailPublishTime === wechatPublishTime &&
            item.crawlTime === emailPublishTime
          ) {
            timeArr.push(item.crawlTime + ' 邮件推送微网刊发布')
          } else if (item.crawlTime === emailPublishTime) {
            timeArr.push(item.crawlTime + ' 邮件推送')
          } else if (item.crawlTime === wechatPublishTime) {
            timeArr.push(item.crawlTime + ' 微网刊发布')
          } else {
            timeArr.push(item.crawlTime)
          }
          countArr.push(item.sumVisitCount)
        })
      }
      myChart.setOption(
        {
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: timeArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 15) {
                    res = res.substring(0, 15) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '17%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '官网访问量'
            }
          ],
          series: [
            {
              name: '文献访问总量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  color: function (params) {
                    // 微网刊推送前 邮件推送后 及中间的部分 分别用三部分颜色标识
                    if (params.name.split(' ')[0] < _this.leftPushlishTime) {
                      return '#65A085'
                    } else if (
                      !(params.name.split(' ')[0] < _this.rightPushlishTime)
                    ) {
                      return '#265cca'
                    } else {
                      return '#BBE0E3'
                    }
                  },
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 计算表格内容高度使其居中
    getTableContentHeight(dom, haveLeftTitle, isEcharts) {
      this.$nextTick(() => {
        let computedHeight = 0
        let childrenList = document.getElementsByClassName(dom)[0].children
        for (var i = 0; i < childrenList.length; i++) {
          if (isEcharts) {
            if (childrenList[i].className) {
              computedHeight = computedHeight + childrenList[i].clientHeight
            }
          } else {
            if (childrenList[i].className !== 'table-content') {
              computedHeight = computedHeight + childrenList[i].clientHeight
            }
          }
        }
        let fanalHeight = 525 - computedHeight - (haveLeftTitle ? 20 : 0)
        for (var j = 0; j < childrenList.length; j++) {
          if (isEcharts) {
            if (!childrenList[j].className) {
              childrenList[j].style.height = fanalHeight + 'px'
            }
          } else {
            if (childrenList[j].className === 'table-content') {
              childrenList[j].style.height = fanalHeight + 'px'
            }
          }
        }
      })
    }
  }
}
</script>
<style lang="scss">
.pdfLoading {
  .el-loading-spinner .el-loading-text,
  .el-loading-spinner > .el-icon-loading {
    display: block;
    color: #454647;
  }
}
</style>
<style lang="scss" scoped>
.report {
  margin: auto;
  .download-btn {
    display: block;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #4054af;
    display: flex;
    line-height: 27px;
    justify-content: flex-end;
    margin: 30px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
  .download-btn-disabled {
    color: #c0c4cc;
    cursor: no-drop;
  }
  .report-content {
    width: 1040px;
    margin: auto;
  }
  .page-part {
    position: relative;
    // border: 1px solid #c0c4cc;
    border-bottom: 0;
    padding: 30px;
    height: 525px;
    .tab-ul {
      li {
        background: #f0f2f5;
        font-size: 18px;
        text-align: center;
        width: 360px;
        padding: 5px 0;
        position: relative;
        margin: 45px auto;
        height: 30px;
        line-height: 30px;
      }
      li.active {
        color: #fff;
        background: #4054af;
      }
      .yq {
        width: 60px;
        height: 60px;
        background: #4054af;
        color: #fff;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        line-height: 60px;
        position: absolute;
        left: -36px;
        top: -12px;
      }
    }
    .table-content {
      display: flex;
      align-items: center;
      .table-content-box {
        margin: auto;
        width: 100%;
      }
    }
    .position-bottom {
      padding-top: 20px;
      .echarts-line {
        display: flex;
        align-items: center;
        justify-content: center;
        .line1 {
          width: 50%;
          height: 2px;
          background: #c6c3c3;
        }
        .line2 {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          margin: 0 8px;
          background: #c6c3c3;
        }
      }
      .bottom-info {
        display: flex;
        font-size: 12px;
        color: #929090;
        margin-top: 20px;
        line-height: 16px;
        .bottom-info-left {
          width: 50%;
        }
        .bottom-info-right {
          width: 50%;
          text-align: justify;
        }
      }
    }
    .echarts-first-title {
      color: #4054af;
      font-size: 18px;
      font-weight: bold;
    }
    .echarts-second-title {
      text-align: center;
    }
    .echarts-left-title {
      margin-top: 20px;
      span {
        color: #4054af;
      }
    }
  }
  .flex-content {
    display: flex;
  }
  .page1 {
    align-items: center;
    justify-content: center;
    img {
      width: 320px;
      height: 456px;
      object-fit: contain;
    }
    .right {
      margin-left: 100px;
      .line {
        width: 70%;
        background: #65a085;
        height: 1px;
        margin: 20px 0;
      }
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #4054af;
        letter-spacing: 6px;
      }
      li {
        list-style-type: disc;
        line-height: 30px;
        font-size: 18px;
        margin-left: 20px;
      }
    }
  }
  .page2,
  .page8,
  .page18,
  .page20 {
    display: flex;
    .tab-ul {
      margin: auto;
    }
  }
  .page9 {
    .situation {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 97%;
      .situation-info {
        width: 45%;
        text-align: center;
        .title {
          font-size: 16px;
          font-weight: bold;
        }
        .info {
          margin-top: 6%;
          .text-box {
            width: 34%;
            display: inline-block;
            border: 1px solid rgb(47, 133, 204);
            font-size: 14px;
            border-radius: 10px;
            padding: 10% 10px;
            span {
              display: block;
              color: #4054af;
              font-size: 20px;
              margin-top: 6px;
            }
          }
          .ml-percent16 {
            margin-left: 12%;
          }
          .mt-percent16 {
            margin-top: 12%;
          }
        }
      }
    }
  }
}
</style>
